.react-calendar {
	width: 280px;
	max-width: 100%;
	background: white;
	/*border: 1px solid #a0a096;*/
	/*font-family: Arial, Helvetica, sans-serif;*/
	font-family: 'PT Sans', sans-serif !important;
	line-height: 1.125em;
}

.react-calendar * {
	font-family: 'PT Sans', sans-serif !important;
}

.react-calendar--doubleView {
	width: 570px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	flex-shrink: 0;
	/* margin: -0.5em; */
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	flex-grow: 0;
	flex-shrink: 0;
	/* margin: 0.5em; */
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}
.react-calendar button:enabled:hover {
	cursor: pointer;
}
.react-calendar__navigation {
	height: 44px;
	margin-bottom: 1em;
    display: flex;
	flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}
.react-calendar__navigation button {
	min-width: 44px;
	background: none;
	color: #004685;
	font-weight: bold;
}

.react-calendar__navigation > button {
	flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.react-calendar__navigation button:not(.react-calendar__navigation__label) {
	font-size: 21px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
	background-color: #f0f0f0;
}

.react-calendar__month-view:first-child {
	/* padding-right: 8px; */
	border-right: 1px solid #D5E3F0;
	margin-right: 8px;
}

@media (max-width: 659px) {
	.react-calendar__month-view:first-child {
		padding-right: 0;
		border-right: none;
		margin-right: 0;
	}
}

.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
	flex-basis: 40px !important;
	color: #004685;
}
.react-calendar__month-view__weekNumbers {
	font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	/* padding: calc(0.75em / 0.75) calc(0.5em / 0.75); */
}
.react-calendar__month-view__days__day--weekend {
	color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em !important;
	flex-basis: 33.3333% !important;
}
.react-calendar__tile {
	/*max-width: 100%;*/
	text-align: center;
	/*padding: 0.75em 0.5em;*/
	/*padding: 0.5em 0.5em;*/
	background: none;
	font-size: 16px !important;
	/*line-height: 21px !important;*/
	width: 40px !important;
	height: 40px !important;
	padding: 0 !important;
	padding: 0 !important;
	flex-basis: 40px !important;
	color: #004685;
}
.react-calendar__tile:disabled {
	background-color: #f0f0f0;
	color: #4F7EA7;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #e6e6e6;
}
.react-calendar__tile--now {
	background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #ffffa9;
}
.react-calendar__tile--hasActive {
	background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: #a9d4ff;
}
.react-calendar__tile--active {
	background: #0270CB !important;
	color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}
